import { createContext } from "react";

const AuthContext = createContext();

export default AuthContext;

// export const AuthContext = createContext<AuthContext>({
//   user: null,
//   setUser: () => {},
// });
