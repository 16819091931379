import { prevMonth, totalBiWeeklyPayChecks, remainingBiWeeklyPaychecks, remainingMonths } from "./DateUtils"

export const utilEstimatedCurrIncome = (YTDIncome) => {
  let incomePerMonth =  YTDIncome/prevMonth
  let totalIncomeCurrYr = incomePerMonth * 12
  return Math.ceil(totalIncomeCurrYr)
}

export const utilEstimatedTaxPaid = (paycheckValues, q1Taxes, q2Taxes, q3Taxes, q4Taxes) => {
  let plannedWithholding = 0
  let quarterlyPaid = 0
  let totalYTDTaxes = 0
  
  paycheckValues.forEach(paycheck => {
    if(paycheck.YTDTaxes > 0){
      totalYTDTaxes = totalYTDTaxes + paycheck.YTDTaxes
      if(paycheck.payFrequency == "bi-weekly"){
        plannedWithholding  = plannedWithholding + Math.ceil(paycheck.withholding * remainingBiWeeklyPaychecks())
      }
      else if(paycheck.payFrequency == "bi-monthly"){
        plannedWithholding  = plannedWithholding + Math.ceil(paycheck.withholding * (remainingMonths*2))
      }
      else{
        plannedWithholding  = plannedWithholding + Math.ceil(paycheck.withholding * remainingMonths)
      }
    }
  });
  for(let i=1;i<5;i++){
    if (eval('(q' + i + 'Taxes)')>0){
      // console.log(eval('(q' + i + 'Taxes)'))
      quarterlyPaid = quarterlyPaid + eval('(q' + i + 'Taxes)')
    }
  }
  return totalYTDTaxes+plannedWithholding+quarterlyPaid
}

export const freqOptions = [
  {
    value: "bi-weekly",
    label: "Bi-weekly",
  },
  {
    value: "bi-monthly",
    label: "Bi-monthly",
  },
  {
    value: "monthly",
    label: "Monthly",
  }
]

export const utilAddPaycheck = (paycheckValues) => {
  return [...paycheckValues, {YTDTaxes:0, withholding:0, payFrequency:"bi-weekly"}]
}
export const utilRemovePayCheck = (i, paycheckValues) => {
  let newPaycheckValues = [...paycheckValues];
  newPaycheckValues.splice(i, 1);
  return newPaycheckValues
}
export const utilWithholding = (event, newFormValues) => {
  let parseId
  parseId = event.target.id.split("withholding-")
  newFormValues[parseId[1]][event.target.name] = parseInt(event.target.value);
  return newFormValues;
}
export const utilUpdateWithholding = (newFormValues, splitIdentifier, formIdentifier, valueIdentifier, value) => {
  let parseId
  let paidPerPaycheck = 0
  let payFrequency = ""
  let YTDTaxes = 0
  parseId = splitIdentifier.split(formIdentifier)
  if(formIdentifier.includes("payFrequency")){
    newFormValues[parseId[1]][valueIdentifier] = value
    payFrequency = value
    YTDTaxes = newFormValues[parseId[1]]['YTDTaxes']
  }
  else{
    newFormValues[parseId[1]][valueIdentifier] = parseInt(value)
    payFrequency = newFormValues[parseId[1]]['payFrequency']
    YTDTaxes = parseInt(value)
  }
  if(payFrequency === "bi-weekly"){
    paidPerPaycheck =  Math.ceil(YTDTaxes/(totalBiWeeklyPayChecks()-remainingBiWeeklyPaychecks()))    
  }
  else if(payFrequency === "bi-monthly"){
    paidPerPaycheck =  Math.ceil((YTDTaxes/prevMonth)/2)
  }
  else{
    paidPerPaycheck =  Math.ceil(YTDTaxes/prevMonth)
  }
  newFormValues[parseId[1]]['withholding'] = paidPerPaycheck
  return newFormValues
}