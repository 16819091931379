import React, { useState } from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import StateForm from "./StateForm";
import SpeedIcon from '@mui/icons-material/Speed';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import {red, green, orange } from '@mui/material/colors';

const StateModal = (props) => {
  const [state, setState] = useState(props.state);

  const [sOpen, setSOpen] = React.useState(false);
  const handleSOpen = () => {
    setSOpen(true);
  };
  const handleSClose = () => {
    setSOpen(false);
  };

  const onSubmitData = (enteredData) => {
    setState(enteredData.state);
  };

  const onStateDelete = (deletedState) => {
    props.onStateDelete(deletedState)
  };

  return (
    <TableRow key={props.state.name}>
      <TableCell component="th" scope="row">
      <Link  onClick={handleSOpen}>{props.state.name}</Link>
      <Modal
        open={sOpen}
        onClose={handleSClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <><StateForm state={state} filingStatus={props.filingStatus} id={props.id} onSubmitData={onSubmitData} required={false} disabled={true} onStateDelete={onStateDelete} /></>
      </Modal>
      </TableCell>
      <TableCell>
        <Tooltip title={
          <>
            <Stack direction="row" alignItems="center" gap={1}>
              <SpeedIcon fontSize="small" sx={{ color: green[500]}}/> You are on track
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <SpeedIcon fontSize="small" sx={{ color: orange[500]}}/> You are within 10%
            </Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <SpeedIcon fontSize="small" sx={{ color: red[800]}}/> You are not on track
            </Stack>
          </>
        }>
          <SpeedIcon sx={{ color: state.onTrack? green[500] : state.onTrack===""? orange[500]: red[800] }}/>
        </Tooltip>
      </TableCell>
      <TableCell>{state.updateDate}</TableCell>
    </TableRow>
  );
}
export default StateModal;