import React, { useState, useEffect, useContext } from "react";
import { styled } from '@mui/material/styles';
import AuthContext from "../context/AuthContext"
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CollapsableRow from "../components/CollapsableRow";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import NewClient from "../components/NewClient";
import Typography from '@mui/material/Typography';
import {withRequiredAuthInfo} from "@propelauth/react"
import axios from 'axios'
axios.defaults.withCredentials = true

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.primary.light,
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Dashboard = withRequiredAuthInfo((props) => {
  const { activeOrg } = useContext(AuthContext);
  const [clients, setClients] = useState([])
  const [orgAccessToken, setOrgAccessToken] = useState("")

  useEffect(() => {
    props.tokens.getAccessTokenForOrg(activeOrg)
    .then((res) => {
      // console.log(res.accessToken)
      setOrgAccessToken(res.accessToken);
    })
  }, [activeOrg])

  useEffect(() => {
    if(orgAccessToken !== ""){
      axios.get(`${process.env.REACT_APP_BACKEND_URL}clients/all`,{
        headers:{
          // Authorization: `Bearer ${props.accessToken}`
          Authorization: `Bearer ${orgAccessToken}`
        }
      })
      .then((res) => {
        // console.log(res.data.clients)
        setClients(res.data.clients);
      })
      .catch((err) =>{
        console.error(err)
      })
    }
  }, [orgAccessToken])

  const [mOpen, setMOpen] = useState(false);
  const handleOpen = () => {
    setMOpen(true);
  };
  const handleClose = () => {
    setMOpen(false);
  };

  const onSubmitData = (enteredData) => {
    setClients([enteredData, ...clients]);
  };

  const onClientDelete = (deletedClient) => {
    let newClients = clients.filter(client => client._id !== deletedClient);
    setClients(newClients);
  };

  return (
    <Box sx={{width: "100%"}}>
      <Grid container spacing={2} textAlign="right">
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" size="small" onClick={handleOpen}>Add Client</Button>
          <Modal
            open={mOpen}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <React.Fragment key="new">
              <NewClient onSubmitData={onSubmitData} noOfClients={clients.length}/>
            </React.Fragment>
          </Modal>
        </Grid>
      </Grid>
      <TableContainer>
        <Table aria-label="collapsible table" style={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="center">On Track</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { clients && clients.length>0 && 
              <React.Fragment>
                {clients.map((row) => (
                  <React.Fragment key={row.name}>
                    <CollapsableRow key={row.name} row={row} onClientDelete={onClientDelete}/>
                  </React.Fragment>
                ))}
              </React.Fragment>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="subtitle2" sx={{m:2, color:'primary.light'}}> For support email support@taxymate.com</Typography>
    </Box>
  );
})

export default Dashboard;