export const filingStatuses = [
  {
    label: "Single",
    value: "single"
  },
  {
    label: "Married Filing Jointly",
    value: "married_j"
  },
  {
    label: "Married Filing Seperately",
    value: "married_s"
  },
  // {
  //   label: "Head of Household",
  //   value: "head"
  // },
]

export const federalRates = {
  single: {
    11600: 0.1,
    47150: 0.12,
    105250: 0.22,
    191950: 0.24,
    243725: 0.32,
    609350: 0.35, //rest is 37
  },
  married_j: {
    23200: 0.1,
    94300: 0.12,
    201050: 0.22,
    383900: 0.24,
    487450: 0.32,
    731200: 0.35, //rest is 37
  },
  married_s: {
    11600: 0.1,
    47150: 0.12,
    105250: 0.22,
    191950: 0.24,
    243725: 0.32,
    609350: 0.35, //rest is 37
  },
  head: {
    15700: 0.1,
    59850: 0.12,
    95350: 0.22,
    182100: 0.24,
    231250: 0.32,
    578100: 0.35,  //rest is 37
  },
}

export const stateOptions = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "DC", label: "Washington DC" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" }
]

export const statesRates = {
  AL: {
    single:{
      rates: {
        500: 0.02,
        3000: 0.04,
      },
      upper: 0.05
    },
    married_j:{
      rates: {
        1000: 0.02,
        6000: 0.04,
      },
      upper: 0.05
    }
  },
  AZ: {
    single:{
      upper: 0.025
    },
    married_j:{
      upper: 0.025
    }
  },
  AR: {
    single:{
      rates: {
        4400: 0.02,
        8800: 0.04,
      },
      upper: 0.044
    },
    married_j:{
      rates: {
        4400: 0.02,
        8800: 0.04,
      },
      upper: 0.044
    }
  },
  CA: {
    single:{
      rates: {
        10412: 0.01,
        24684: 0.02,
        38959: 0.04,
        54081: 0.06,
        68350: 0.08,
        349137: 0.093,
        418961: 0.103,
        698271: 0.113,
        1000000: 0.123
      },
      upper: 0.133
    },
    married_j:{
      rates: {
        20824: 0.01,
        49368: 0.02,
        77918: 0.04,
        108162: 0.06,
        136700: 0.08,
        698274: 0.093,
        837922: 0.103,
        1000000: 0.113,
        1396542: 0.123
      },
      upper: 0.133
    }
  },
  CO: {
    single:{
      upper: 0.044
    },
    married_j:{
      upper: 0.044
    }
  },
  CT: {
    single:{
      rates: {
        10000: 0.02,
        50000: 0.045,
        100000: 0.055,
        200000: 0.06,
        250000: 0.065,
        500000: 0.069
      },
      upper: 0.0699
    },
    married_j:{
      rates: {
        20000: 0.02,
        100000: 0.045,
        200000: 0.055,
        400000: 0.06,
        500000: 0.065,
        1000000: 0.069
      },
      upper: 0.0699
    }
  },
  DE: {
    single:{
      rates: {
        2000: 0.0,
        5000: 0.022,
        10000: 0.039,
        20000: 0.048,
        25000: 0.052,
        60000: 0.0555
      },
      upper: 0.066
    },
    married_j:{
      rates: {
        2000: 0.0,
        5000: 0.022,
        10000: 0.039,
        20000: 0.048,
        25000: 0.052,
        60000: 0.0555
      },
      upper: 0.066
    }
  },
  GA: {
    single:{
      upper: 0.0549
    },
    married_j:{
      upper: 0.0549
    }
  },
  HI: {
    single:{
      rates: {
        2400: 0.014,
        4800: 0.032,
        9600: 0.055,
        14400: 0.064,
        19200: 0.068,
        24000: 0.072,
        36000: 0.076,
        48000: 0.079,
        150000: 0.0825,
        175000: 0.09,
        200000: 0.10
      },
      upper: 0.11
    },
    married_j:{
      rates: {
        4800: 0.014,
        9600: 0.032,
        19200: 0.055,
        28800: 0.064,
        38400: 0.068,
        48000: 0.072,
        72000: 0.076,
        96000: 0.079,
        300000: 0.0825,
        350000: 0.09,
        400000: 0.10
      },
      upper: 0.11
    }
  },
  ID: {
    single:{
      rates: {
        4489: 0
      },
      upper: 0.058
    },
    married_j:{
      rates: {
        8978: 0
      },
      upper: 0.058
    }
  },
  IL: {
    single:{
      upper: 0.0495
    },
    married_j:{
      upper: 0.0495
    }
  },
  IN: {
    single:{
      upper: 0.0305
    },
    married_j:{
      upper: 0.0305
    }
  },
  IA: {
    single:{
      rates: {
        6210: 0.044,
        31050: 0.0482
      },
      upper: 0.057
    },
    married_j:{
      rates: {
        12420: 0.044,
        62100: 0.0482
      },
      upper: 0.057
    }
  },
  KS: {
    single:{
      rates: {
        15000: 0.0310,
        30000: 0.0525
      },
      upper: 0.057
    },
    married_j:{
      rates: {
        30000: 0.0310,
        60000: 0.0525
      },
      upper: 0.057
    }
  },
  KY: {
    single:{
      upper: 0.04
    },
    married_j:{
      upper: 0.04
    }
  },
  LA: {
    single:{
      rates: {
        12500: 0.0185,
        50000: 0.035
      },
      upper: 0.0425
    },
    married_j:{
      rates: {
        12500: 0.0185,
        50000: 0.035
      },
      upper: 0.0425
    }
  },
  ME: {
    single:{
      rates: {
        26050: 0.058,
        61600: 0.0675
      },
      upper: 0.0715
    },
    married_j:{
      rates: {
        52100: 0.058,
        123250: 0.0675
      },
      upper: 0.0715
    }
  },
  MD: {
    single:{
      rates: {
        1000: 0.02,
        2000: 0.03,
        3000: 0.04,
        100000: 0.0475,
        125000: 0.05,
        150000: 0.0525,
        250000: 0.055
      },
      upper: 0.0575
    },
    married_j:{
      rates: {
        1000: 0.02,
        2000: 0.03,
        3000: 0.04,
        150000: 0.0475,
        175000: 0.05,
        225000: 0.0525,
        300000: 0.055
      },
      upper: 0.0575
    }
  },
  MA: {
    single:{
      rates: {
        1000000: 0.05
      },
      upper: 0.09
    },
    married_j:{
      rates: {
        1000000: 0.05
      },
      upper: 0.09
    }
  },
  MI: {
    single:{
      upper: 0.0425
    },
    married_j:{
      upper: 0.0425
    }
  },
  MN: {
    single:{
      rates: {
        31690: 0.0535,
        104090: 0.068,
        193240: 0.0785
      },
      upper: 0.0985
    },
    married_j:{
      rates: {
        46330: 0.0535,
        184040: 0.068,
        321450: 0.0785
      },
      upper: 0.0985
    }
  },
  MS: {
    single:{
      rates: {
        10000: 0.0
      },
      upper: 0.047
    },
    married_j:{
      rates: {
        10000: 0.0
      },
      upper: 0.047
    }
  },
  MO: {
    single:{
      rates: {
        1273: 0.0,
        2546: 0.02,
        3819: 0.025,
        5092: 0.03,
        6365: 0.035,
        7638: 0.04,
        8911: 0.045
      },
      upper: 0.048
    },
    married_j:{
      rates: {
        1207: 0.0,
        2414: 0.02,
        3621: 0.025,
        4828: 0.03,
        6035: 0.035,
        7242: 0.04,
        8449: 0.045
      },
      upper: 0.048
    }
  },
  MT: {
    single:{
      rates: {
        20500: 0.047
      },
      upper: 0.059
    },
    married_j:{
      rates: {
        41000: 0.047
      },
      upper: 0.059
    }
  },
  NE: {
    single:{
      rates: {
        3700: 0.0246,
        22170: 0.0351,
        35730: 0.0501
      },
      upper: 0.0484
    },
    married_j:{
      rates: {
        7390: 0.0246,
        44350: 0.0351,
        71460: 0.0501
      },
      upper: 0.0484
    }
  },
  NJ: {
    single:{
      rates: {
        20000: 0.014,
        35000: 0.0175,
        40000: 0.035,
        75000: 0.05525,
        500000: 0.0637,
        1000000: 0.0897
      },
      upper: 0.1075
    },
    married_j:{
      rates: {
        20000: 0.014,
        50000: 0.0175,
        70000: 0.0245,
        80000: 0.035,
        150000: 0.05525,
        500000: 0.0637,
        1000000: 0.0897
      },
      upper: 0.1075
    }
  },
  NM: {
    single:{
      rates: {
        5500: 0.017,
        11000: 0.032,
        16000: 0.047,
        210000: 0.049
      },
      upper: 0.059
    },
    married_j:{
      rates: {
        8000: 0.017,
        16000: 0.032,
        24000: 0.047,
        315000: 0.049
      },
      upper: 0.059
    }
  },
  NY: {
    single:{
      rates: {
        8500: 0.04,
        11700: 0.045,
        13900: 0.0525,
        80650: 0.055,
        215400: 0.06,
        1077550: 0.0685,
        5000000: 0.0965,
        25000000: 0.103
      },
      upper: 0.109
    },
    married_j:{
      rates: {
        17150: 0.04,
        23600: 0.045,
        27900: 0.0525,
        161550: 0.055,
        323200: 0.06,
        2155350: 0.0685,
        5000000: 0.0965,
        25000000: 0.103
      },
      upper: 0.109
    }
  },
  NC: {
    single:{
      upper: 0.045
    },
    married_j:{
      upper: 0.045
    }
  },
  ND: {
    single:{
      rates: {
        44725: 0.0,
        225975: 0.0195
      },
      upper: 0.025
    },
    married_j:{
      rates: {
        74750: 0.0,
        275100: 0.0195
      },
      upper: 0.025
    }
  },
  OH: {
    single:{
      rates: {
        26050: 0.0,
        92150: 0.0275
      },
      upper: 0.035
    },
    married_j:{
      rates: {
        26050: 0.0,
        92150: 0.0275
      },
      upper: 0.035
    }
  },
  OK: {
    single:{
      rates: {
        1000: 0.0025,
        2500: 0.0075,
        3750: 0.0175,
        4900: 0.0275,
        7200: 0.0375
      },
      upper: 0.0475
    },
    married_j:{
      rates: {
        2000: 0.0025,
        5000: 0.0075,
        7500: 0.0175,
        9800: 0.0275,
        12200: 0.0375
      },
      upper: 0.0475
    }
  },
  OR: {
    single:{
      rates: {
        4300: 0.0475,
        10750: 0.0675,
        125000: 0.0875
      },
      upper: 0.099
    },
    married_j:{
      rates: {
        8600: 0.0475,
        21500: 0.0675,
        250000: 0.0875
      },
      upper: 0.099
    }
  },
  PA: {
    single:{
      upper: 0.0307
    },
    married_j:{
      upper: 0.0307
    }
  },
  RI: {
    single:{
      rates: {
        77450: 0.0375,
        176050: 0.0475
      },
      upper: 0.0599
    },
    married_j:{
      rates: {
        77450: 0.0375,
        176050: 0.0475
      },
      upper: 0.0599
    }
  },
  SC: {
    single:{
      rates: {
        3460: 0.0,
        17330: 0.03
      },
      upper: 0.064
    },
    married_j:{
      rates: {
        3460: 0.0,
        17330: 0.03
      },
      upper: 0.064
    }
  },
  UT: {
    single:{
      upper: 0.0465
    },
    married_j:{
      upper: 0.0465
    }
  },
  VT: {
    single:{
      rates: {
        45400: 0.0335,
        110050: 0.066,
        229550: 0.076,
      },
      upper: 0.0875
    },
    married_j:{
      rates: {
        75850: 0.0335,
        183400: 0.066,
        279450: 0.076,
      },
      upper: 0.0875
    }
  },
  VA: {
    single:{
      rates: {
        3000: 0.02,
        5000: 0.03,
        17000: 0.05
      },
      upper: 0.0575
    },
    married_j:{
      rates: {
        3000: 0.02,
        5000: 0.03,
        17000: 0.05
      },
      upper: 0.0575
    }
  },
  WV: {
    single:{
      rates: {
        10000: 0.0236,
        25000: 0.0315,
        40000: 0.0354,
        60000: 0.0472
      },
      upper: 0.0512
    },
    married_j:{
      rates: {
        10000: 0.0236,
        25000: 0.0315,
        40000: 0.0354,
        60000: 0.0472
      },
      upper: 0.0512
    }
  },
  WI: {
    single:{
      rates: {
        14320: 0.035,
        28640: 0.044,
        315310: 0.053
      },
      upper: 0.0765
    },
    married_j:{
      rates: {
        19090: 0.035,
        38190: 0.044,
        420420: 0.053
      },
      upper: 0.0765
    }
  },
  DC: {
    single:{
      rates: {
        10000: 0.04,
        40000: 0.06,
        60000: 0.065,
        250000: 0.085,
        500000: 0.0925,
        1000000: 0.0975
      },
      upper: 0.1075
    },
    married_j:{
      rates: {
        10000: 0.04,
        40000: 0.06,
        60000: 0.065,
        250000: 0.085,
        500000: 0.0925,
        1000000: 0.0975
      },
      upper: 0.1075
    }
  }
}

export const statesDates = {
  HI: [
    "20 April",
    "20 June",
    "20 Sep",
    "20 Jan"
  ],
  ID: [
    "15 April",
    "15 June",
    "15 Sep",
    "15 Dec"
  ],
  IA: [
    "30 April",
    "30 June",
    "30 Sep",
    "31 Jan"
  ],
  MT: [
    "15 April",
    "15 June",
    "15 Sep",
    "15 Dec"
  ],
  UT: [
    "30 April",
    "31 July",
    "31 Oct",
    "31 Jan"
  ],
  VA: [
    "01 May",
    "15 June",
    "15 Sep",
    "15 Jan"
  ],
  WV: [
    "20 April",
    "20 July",
    "20 Oct",
    "20 Jan"
  ]
}

export const federalDates = [
  "15 April",
  "15 June",
  "15 Sep",
  "15 Jan"
]