import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../context/AuthContext"
import { Outlet, Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Link as LinkBase } from "@mui/material";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import { pink, indigo } from '@mui/material/colors';
import { withAuthInfo, useHostedPageUrls, useLogoutFunction, saveOrgSelectionToLocalStorage } from '@propelauth/react'

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: indigo[400],
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary:{
      main: pink[400],
    }
  },
});

const OrgSelector = withAuthInfo((props) => {
  const { activeOrg, checkActiveOrg } = useContext(AuthContext);
  const orgs = props.userClass.getOrgs()

  useEffect(() => {
    if(activeOrg === null || activeOrg === "undefined" || !props.orgHelper.getOrg(activeOrg)){
      console.log("inside")
      saveOrgSelectionToLocalStorage(orgs[0].orgId)
      checkActiveOrg()
    }
  }, [activeOrg])

  const handleClose = (event) => {
    saveOrgSelectionToLocalStorage(event.target.getAttribute('orgid'))
    checkActiveOrg()
  };

  return (
    <>
      {orgs.map((org) => (
        <MenuItem 
          key={org.orgId} 
          orgid={org.orgId} 
          selected={org.orgId === activeOrg}
          onClick={handleClose}>{org.orgName}
        </MenuItem>
      ))}
    </>
  )
})

const RootLayout = withAuthInfo((props) => {
  const { activeOrg } = useContext(AuthContext);
  const logoutFunction = useLogoutFunction()
  const { getLoginPageUrl, getAccountPageUrl } = useHostedPageUrls()
  const [anchorElUser, setAnchorElUser] = useState(null);
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`}}
        >
          <Toolbar sx={{ flexWrap: "wrap" }} variant="dense">
            <Box sx={{ flexGrow: 1 }}>
              <IconButton href="/" sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
                <Avatar alt="profile" src="Tax-logo.png"/>
              </IconButton>
              <LinkBase
                variant="button"
                color="primary"
                underline="none"
                component={Link}
                to="/"
                sx={{ my: 1, mx: 1.5 }}
              >
                Home
              </LinkBase>
              {!props.isLoggedIn &&
                <LinkBase
                  variant="button"
                  color="primary"
                  underline="none"
                  component={Link}
                  to="/calculate"
                  sx={{ my: 1, mx: 1.5 }}
                >
                  Estimates Calc
                </LinkBase>
              }
              {props.isLoggedIn &&
                <>
                  <LinkBase
                    variant="button"
                    color="primary"
                    underline="none"
                    component={Link}
                    to="/dashboard"
                    sx={{ my: 1, mx: 1.5 }}
                  >
                    Dashboard
                  </LinkBase>
                </>
              }
              {!props.isLoggedIn &&
                <LinkBase
                  variant="button"
                  color="primary"
                  underline="none"
                  component={Link}
                  to={getLoginPageUrl()}
                  sx={{ my: 1, mx: 1.5 }}
                >
                  Login
                </LinkBase>
              }
            </Box>
            {props.isLoggedIn &&
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings" >
                  {props.orgHelper.getOrg(activeOrg) && <Typography variant="caption" sx={{ display: { xs: 'none', sm: 'inline' } }}>Org: {props.orgHelper.getOrg(activeOrg).orgName} </Typography>}
                  <IconButton size="small" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="profile" src={props.user.pictureUrl}/>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="account-menu"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <OrgSelector/>
                  <Divider sx={{ bgcolor: "#ED3C6A"}}/>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    <LinkBase
                      variant="button"
                      color="primary"
                      underline="none"
                      component={Link}
                      to={getAccountPageUrl()}
                    >
                      Account
                    </LinkBase>
                  </MenuItem>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    <Button  size="small" onClick={() => logoutFunction(true)}>Logout</Button>
                  </MenuItem>
                </Menu>
              </Box>
            }
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            m: 1,
            pt:1
          }}
        >
          <Outlet />
        </Box>
      </Container>
    </ThemeProvider>
  );
})

export default RootLayout;
