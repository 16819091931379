// import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useEffect, useState } from 'react'
import HomePage from "./pages/Home";
import RootLayout from "./pages/Root";
import Dashboard from "./pages/Dashboard";
import Calculate from "./pages/Calculate";
import AuthContext from "./context/AuthContext"
import { loadOrgSelectionFromLocalStorage } from '@propelauth/react'
import ReactGA from "react-ga4";

ReactGA.initialize("G-GSZD9B6JDP");

const AuthContextProvider = ({ children }) => {
  const [activeOrg, setActiveOrg] = useState(loadOrgSelectionFromLocalStorage());
  const checkActiveOrg = () => {
    const orgId = loadOrgSelectionFromLocalStorage()
    setActiveOrg(orgId)
  }
  useEffect(() => {
    checkActiveOrg()
  }, [checkActiveOrg])

  return <AuthContext.Provider value={{ activeOrg, checkActiveOrg }}>{children}</AuthContext.Provider>
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/calculate", element: <Calculate /> },
    ]
  }
]);

export default function App() {
  return (
    <AuthContextProvider>
      <RouterProvider router={router} />
    </AuthContextProvider>
  );
}

