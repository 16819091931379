import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function SelectInput(props) {
  return (
    <FormControl fullWidth sx={{ m: 1 }} required={props.required} disabled={props.disabled} error={props.error}>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>
      <Select
        labelId={props.labelId}
        id={props.id}
        name={props.id}
        value={props.value}
        label={props.label}
        onChange={props.handleChange}
        // defaultValue={props.default}
      >
        {props.options.map((option) => (
          <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectInput;