import React from "react";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AmountInput from "../components/AmountInput";
import { statesDates, federalDates } from '../data/taxrates';

function DatesListForm(props) {
  const curr_year = new Date().getFullYear()
  const prevMonth = new Date().getMonth() 
  const dateInPast = (date) => {
    if(prevMonth>0){ // It not jan following year
      let estDate = date+" " + curr_year
      if(date.includes("Jan")){
        estDate = date+" " + (curr_year+1)
      }
      if (new Date() > new Date(estDate)){
        return "Paid"
      }
    }
    else{
      let estDate = date+" " + curr_year-1
      if(date.includes("Jan")){
        estDate = date+" " + (curr_year)
      }
      if (new Date() > new Date(estDate)){
        return "Paid"
      }
    }
    return "Planned"
  }
  return (
    <>
      {statesDates.hasOwnProperty(props.stateName)
        ?<>
          {statesDates[props.stateName].map((date, index) => (
            <React.Fragment key={index}>
              <Grid item xs={4} md={2}>
                <Stack alignItems="center" direction="row" gap={2} sx={{mt:2}}>
                  <CalendarMonthIcon sx={{ display: { xs: 'none', sm: 'block' } }} />
                  <Typography variant="body1">{date}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={8} md={4}>
                <AmountInput 
                  id={`q${index+1}-taxes`}
                  label={`Q${index+1} Taxes Paid`}
                  placeholder="2000"
                  size="small"
                  handleChange={props.handleChange}
                  defaultValue ={props[`q${index+1}Taxes`]}
                />
              </Grid>
            </React.Fragment>
          ))}
        </>
        :<>
          {federalDates.map((date, index) => (
            <React.Fragment key={index}>
              <Grid item xs={4} md={2}>
                <Stack alignItems="center" direction="row" gap={2} sx={{mt:2}}>
                  <CalendarMonthIcon sx={{ display: { xs: 'none', sm: 'block' } }} />
                  <Typography variant="body1">{date}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={8} md={4}>
                <AmountInput 
                  id={`q${index+1}-taxes`}
                  label={`Q${index+1} Taxes ${dateInPast(date)}`}
                  placeholder="2000"
                  size="small"
                  handleChange={props.handleChange}
                  value ={props[`q${index+1}Taxes`]}
                />
              </Grid>
            </React.Fragment>
          ))}
        </>
      }
    </>
    
  );
}
export default DatesListForm;