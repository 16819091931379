import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import FederalForm from "../components/FederalForm";
import StateForm from "../components/StateForm";
import Button from '@mui/material/Button';
import Footer from "../components/Footer";

export default function Calculate() {
  const [filingStatus, setfilingStatus] = useState("single");
  const [projectedIncome, setProjectedIncome] = useState(0);
  const [showStateForm, setShowStateForm] = useState(false);

  const onSubmitFedData = (enteredData) => {
    setfilingStatus(enteredData.filingStatus)
    if(enteredData.projectedIncome !== ""){setProjectedIncome(enteredData.projectedIncome)}
  };

  const handleAddStateForm = () => {
    setShowStateForm(true);
  };

  return (
    <Box>
      <Typography variant="h4">Federal Safe Harbor Estimated Tax</Typography>
      <FederalForm  styling="page" onSubmitData={onSubmitFedData}/>
      {!showStateForm && 
      <Button variant="contained" color="secondary" onClick={handleAddStateForm}>
        Calculate State Estimates
      </Button>
      }
      {showStateForm && 
      <>
        <Typography variant="h4">State Safe Harbor Estimated Tax</Typography>
        <StateForm styling="page" filingStatus={filingStatus} projectedIncome={projectedIncome} />
      </>
      }
      <Footer/>
    </Box>
  )
}
  