import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PaidIcon from '@mui/icons-material/Paid';
import {green, pink } from '@mui/material/colors';
import CelebrationIcon from '@mui/icons-material/Celebration';

function PaymentList(props) {
  return (
    <List>
      {props.tax &&
        <>
          <ListItem>
            <ListItemText
              secondary={`Projected tax liability is $${props.tax.toLocaleString()}`}
            />
          </ListItem>
        </>
      }
      <ListItem>
        <ListItemIcon sx={{minWidth:"10%", color: props.monthly > 0?green[500]:pink[400]}}>
          {props.monthly > 0
            ?<PaidIcon />
            :<CelebrationIcon/>
          }
        </ListItemIcon>
        <ListItemText
          secondary={props.monthly > 0
            ?`Either increase withholding by $${props.monthly.toLocaleString()}/month`
            :"You are all set."
          }
        />
      </ListItem>
      {props.quarterly > 0 &&
        <ListItem>
          <ListItemIcon sx={{minWidth:"10%", color: green[500]}}>
            <PaidIcon />
          </ListItemIcon>
          <ListItemText
            secondary={`Or make payment of $${props.quarterly.toLocaleString()} for each remaining quarter`}
          />
        </ListItem>
      }
    </List>
  );
}

export default PaymentList;