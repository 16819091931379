import React, { useState } from "react";
import Box from '@mui/material/Box';
import Features from "../components/Features";
import Hero from "../components/Hero";
import Pricing from "../components/Pricing";
import Footer from "../components/Footer";

export default function HomePage() {
  return (
    <Box>
      <Hero/>
      <Features/>
      <Pricing/>
      <Footer/>
    </Box>
  )
}
  