import React from "react";
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

function RadioInput(props) {
  return (
    <FormControl sx={{ m: 1 }} style={{flexDirection: "row"}}>
      <FormLabel id={props.id} 
        sx={{ 
          mt: 1, 
          pr:1, 
          display: 
          props.id.includes('pay-frequency')
          ? { xs: 'none'} 
          : 'auto',
        }}
      >{props.label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby={props.id}
        name={props.name}
        defaultValue="no"
        onChange={props.handleChange} 
        value={props.value}
      >
        {props.options.map((option, index) => (
          <React.Fragment key={index}>
            <FormControlLabel value={option.value} control={<Radio size="small" />} label={option.label} />
          </React.Fragment>
        ))}
      </RadioGroup>
    </FormControl>
  );
}
export default RadioInput;