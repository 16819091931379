import React, { useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import FederalForm from "../components/FederalForm";
import StateModal from "./StateModal";
import StateForm from "./StateForm";
import SpeedIcon from '@mui/icons-material/Speed';
import CircleIcon from '@mui/icons-material/Circle';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import {red, green, orange } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import {withAuthInfo} from "@propelauth/react"
import axios from 'axios'
axios.defaults.withCredentials = true

const CollapsableRow = withAuthInfo((props) => {
  const { row } = props;
  const [federal, setFederal] = useState(row.federal);
  const [states, setStates] = useState(row.states);
  const onTrack = useMemo(() =>{
    let status = federal.onTrack
    if(status === true || status === ""){
      for(let i=0; i<states.length; i++){
        if (states[i].onTrack === false){
          return false
        }
        else if(states[i].onTrack === ""){
          status = states[i].onTrack
        }
      }
    }
    return status
  }, [federal, states])

  //collapse
  const [open, setOpen] = useState(false);
  //fed modal
  const [mOpen, setMOpen] = useState(false);
  const handleOpen = () => {
    setMOpen(true);
  };
  const handleClose = () => {
    setMOpen(false);
  };
  //end modal
  //fed edit
  const onSubmitData = (enteredData) => {
    setFederal(enteredData.federal);
  };
  // end fed edit

  //state modal
  const [sOpen, setSOpen] = useState(false);
  const handleStateOpen = () => {
    setSOpen(true);
  };
  const handleStateClose = () => {
    setSOpen(false);
  };
  //end modal
  //state edit
  const onSubmitStateData = (enteredData) => {
    setStates([...states, enteredData.state]);
  };
  const onStateDelete = (deletedState) => {
    let newStates = states.filter(state => state.name !== deletedState);
    setStates(newStates);
  };
  // end state edit 
  //client delete
  const onClientDelete = async(event) => {
    console.log(row._id)
    const payload = {
      "id": row._id,
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}clients/deleteclient`,
        payload,{
        headers:{
          Authorization: `Bearer ${props.accessToken}`
        }
      })
      props.onClientDelete(row._id)
    } catch (err) {
      console.error(err)
    }
  }
  // end client delete

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="center">
          <Tooltip title={
            <>
              <Stack direction="row" alignItems="center" gap={1}>
                <CircleIcon fontSize="small" sx={{ color: green[500]}}/> You are on track
              </Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                <CircleIcon fontSize="small" sx={{ color: orange[500]}}/> At least one tax filing is within 10%
              </Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                <CircleIcon fontSize="small" sx={{ color: red[800]}}/> At least one is not on track
              </Stack>
            </>
          }>
            <CircleIcon fontSize="small" sx={{ color: onTrack? green[500] :onTrack===""? orange[500]:red[800] }}/>
          </Tooltip>
        </TableCell>
        <TableCell>
          <IconButton size="small"  onClick={onClientDelete}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft:'20%' }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Link  onClick={handleOpen}>Federal</Link>
                      <Modal
                        open={mOpen}
                        onClose={handleClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                      >
                        <React.Fragment key={row._id}>
                          <FederalForm federal={federal} id={row._id} onSubmitData={onSubmitData} styling="modal"/>
                        </React.Fragment>
                      </Modal>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={
                        <>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <SpeedIcon fontSize="small" sx={{ color: green[500]}}/> You are on track
                          </Stack>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <SpeedIcon fontSize="small" sx={{ color: orange[500]}}/> You are within 10%
                          </Stack>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <SpeedIcon fontSize="small" sx={{ color: red[800]}}/> You are not on track
                          </Stack>
                        </>
                      }>
                        <SpeedIcon sx={{ color: federal.onTrack? green[500] :federal.onTrack===""? orange[500]: red[800] }}/>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{federal.updateDate}</TableCell>
                  </TableRow>
                  { states && states.length>0 && 
                    <React.Fragment key={row._id}>
                    {states.map((state) => (
                      <StateModal state={state} filingStatus={federal.filingStatus} id={row._id} key={state.name} styling="modal" onStateDelete={onStateDelete}/>
                    ))}
                    </React.Fragment>
                  }
                  <TableRow>
                    <TableCell>
                      <Button variant="outlined" size="small" color="secondary" onClick={handleStateOpen}>Add State</Button>
                      <Modal
                        open={sOpen}
                        onClose={handleStateClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                      >
                        <React.Fragment key={row._id}>
                        <StateForm projectedIncome={federal.projectedIncome} filingStatus={federal.filingStatus} id={row._id} onSubmitData={onSubmitStateData} styling="modal" required={true} disabled={false}/>
                        </React.Fragment>
                      </Modal>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
});

export default CollapsableRow;