import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../context/AuthContext";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {withRequiredAuthInfo} from "@propelauth/react"
import axios from 'axios'
axios.defaults.withCredentials = true

const NewClient = withRequiredAuthInfo((props) => {
  const { activeOrg } = useContext(AuthContext);
  const [clientName, setClientName] = useState("")
  const [message, setMessage] = useState("")
  const [msgStatus, setMsgStatus] = useState("")
  const [maxClients, setMaxClients] = useState(props.orgHelper.getOrg(activeOrg).orgMetadata.maxClients)

  const handleChange = (event) => {
    setClientName(event.target.value)
  };

  useEffect(() => {
    if(typeof props.orgHelper.getOrg(activeOrg).orgMetadata.maxClients == "undefined"){
      setMaxClients(5)
      axios.post(`${process.env.REACT_APP_BACKEND_URL}org/updateorg`,
        {"orgId": activeOrg, "maxClients": 5},
        {headers:{Authorization: `Bearer ${props.accessToken}`}}
      )
      .then((res) => {
        console.log("initial maxclienst set")
      })
      .catch((err) =>{
        console.error(err)
      })
    }
  }, [])

  const canAddClients = maxClients > props.noOfClients
  const onSave = async(event) => {
    let payload = {
      "name": clientName,
      "orgId": activeOrg,
      "federal": {},
      "states": []
    }
    try {
      const respose = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}clients/newclient`,
        payload, {
        headers:{
          Authorization: `Bearer ${props.accessToken}`
        }
      })
      setMessage("Record Updated")
      setMsgStatus("success")
      // console.log("new client")
      // console.log(respose)
      payload["_id"] = respose.data.client.insertedId
      props.onSubmitData(payload)
    } catch (err) {
      setMessage("Error while saving.")
      setMsgStatus("error")
      console.error(err)
    }
  }

  const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #ED3C6A',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Box
      sx={{ ...style, flexGrow: 1,  mt:5 , mb:5 }}
    >
      {canAddClients
      ?<>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth sx={{ m: 1 }}
              required
              id="client-name"
              label="Name"
              type="Text"
              placeholder="Aruna"
              onChange={handleChange} 
            /> 
          </Grid>
        </Grid>
        <Grid container justifyContent="right">
          <Stack spacing={2} direction="row"  justify="flex-end">
            <Alert severity={msgStatus}>
              {message}
            </Alert>
            <Button variant="contained" color="secondary" onClick={onSave}>Save</Button>
          </Stack>
        </Grid>
      </>
      :<>
        <Alert severity={msgStatus}>
          You need to <a href="/">upgrade</a> to add more clients.
        </Alert>
      </>
      }
    </Box>
  );
});
export default NewClient;