import { statesDates, federalDates } from "../data/taxrates";

export const curr_year = new Date().getFullYear()
export const prev_year = curr_year - 1
export const prevMonth = new Date().getMonth() //Month start from 0
export const remainingMonths = 12 - prevMonth

export const last_day_date_prev_month = () => {
  let date = new Date()
  date.setDate(1)
  date.setHours(-1)
  return date
}
export const last_day_prev_month = () => {
  return last_day_date_prev_month().toLocaleString('en-us',{day: 'numeric', month:'short'})
}
export const labelPrevYear = () => {
  if(prevMonth===0){
    return prev_year - 1
  }
  return prev_year
}
export const labelCurrYear = () => {
  if(prevMonth===0){
    return curr_year - 1
  }
  return curr_year
}
export const isLeapYear = () => {
  return new Date(labelCurrYear(), 1, 29).getDate() === 29;
}
export const firstDay = () => {
  return new Date(labelCurrYear(), 0, 1).getDay();
}
export const totalBiWeeklyPayChecks = () => {
  let paycheckWeeks = 26
  if((firstDay() == 5) || (isLeapYear && (firstDay() == 4 || firstDay() == 5))){
    paycheckWeeks = 27
  }
  return paycheckWeeks
}
export const remainingBiWeeklyPaychecks = () => {
  let endDate = new Date(curr_year, 11, 31)
  if(prevMonth===0 || prevMonth===11){ // It is jan or later following year
    return 0
  }
  let startDate = last_day_date_prev_month()
  let remainingWeeks = Math.round((endDate - startDate) / (7 * 24 * 60 * 60 * 1000))
  return Math.floor(remainingWeeks/2)
}
export const remainingQuarters = (stateName=null) => {
  let pastQuaters = 0
  let dateList = federalDates
  if(statesDates.hasOwnProperty(stateName)){
    dateList = statesDates[stateName]
  }
  for(let i=0; i<dateList.length; i++){
    if(prevMonth>0){ // It not jan following year
      let estDate = dateList[i]+" " + curr_year
      if(dateList[i].includes("Jan")){
        estDate = dateList[i]+" " + (curr_year+1)
      }
      if (new Date() > new Date(estDate)){
        pastQuaters++
      }
      else {
        break
      }
    } else {
      let estDate = dateList[i]+" " + curr_year-1
      if(dateList[i].includes("Jan")){
        estDate = dateList[i]+" " + (curr_year)
      }
      if (new Date() > new Date(estDate)){
        pastQuaters++
      }
      else {
        break
      }
    }
  }
  return 4-pastQuaters
}