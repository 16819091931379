import React, { useState, useMemo, useEffect, useRef } from "react";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import AmountInput from "../components/AmountInput";
import RadioInput from "../components/RadioInput";
import SelectInput from "./SelectInput";
import EstimateTaxGauge from "../components/EstimateTaxGauge";
import Grid from '@mui/material/Grid';
import DatesListForm from "./DateListForm";
import { federalRates, filingStatuses, federalDates } from "../data/taxrates";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import PaymentList from "./PaymentList";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {withAuthInfo} from "@propelauth/react"
import {remainingMonths, last_day_prev_month, labelPrevYear, labelCurrYear, remainingQuarters
} from "../helpers/DateUtils"
import {utilEstimatedTaxPaid, freqOptions, utilAddPaycheck , utilRemovePayCheck,
  utilWithholding, utilUpdateWithholding
} from "../helpers/EstimateUtils"
import axios from 'axios'
axios.defaults.withCredentials = true

const FederalForm = withAuthInfo((props) => {

  const [prevFedTaxes, setPrevFedTaxes] = useState(typeof props.federal != "undefined" && typeof props.federal.prevFedTaxes != "undefined" ? props.federal.prevFedTaxes : 0);
  const [filingStatus, setfilingStatus] = useState(typeof props.federal != "undefined" && typeof props.federal.filingStatus != "undefined"  ? props.federal.filingStatus : "single");
  const [projectedIncome, setProjectedIncome] = useState(typeof props.federal != "undefined" && typeof props.federal.projectedIncome != "undefined" ? props.federal.projectedIncome : 0);
  const [q1Taxes, setQ1Taxes] = useState(typeof props.federal != "undefined" && typeof props.federal.quarterly != "undefined" && typeof props.federal.quarterly.q1Taxes != "undefined" ? props.federal.quarterly.q1Taxes : 0);
  const [q2Taxes, setQ2Taxes] = useState(typeof props.federal != "undefined" && typeof props.federal.quarterly != "undefined" && typeof props.federal.quarterly.q2Taxes != "undefined" ? props.federal.quarterly.q2Taxes : 0);
  const [q3Taxes, setQ3Taxes] = useState(typeof props.federal != "undefined" && typeof props.federal.quarterly != "undefined" && typeof props.federal.quarterly.q3Taxes != "undefined" ? props.federal.quarterly.q3Taxes : 0);
  const [q4Taxes, setQ4Taxes] = useState(typeof props.federal != "undefined" && typeof props.federal.quarterly != "undefined" && typeof props.federal.quarterly.q4Taxes != "undefined" ? props.federal.quarterly.q4Taxes : 0);
  const [paycheckValues, setPaycheckValues] = useState(typeof props.federal != "undefined" && typeof props.federal.paycheckValues != "undefined"  ? props.federal.paycheckValues :[{YTDTaxes:0, withholding:0, payFrequency:"bi-weekly"}])
  const [deductions, setDeductions] = useState(typeof props.federal != "undefined" && typeof props.federal.deductions != "undefined" ? props.federal.deductions : 0);
  const styling = props.styling?props.styling:"modal"

  const addPaycheck = async(event) => {
    setPaycheckValues(utilAddPaycheck(paycheckValues))
  }
  const removePayCheck = (i) => {
    setPaycheckValues(utilRemovePayCheck(i, paycheckValues))
  }
  const paycheckChange = (event) => {
    let newFormValues = [...paycheckValues];
    if(event.target.id.includes('ytd-taxes')){
      newFormValues = utilUpdateWithholding(newFormValues, event.target.id, "ytd-taxes-", event.target.name, event.target.value)
      setPaycheckValues(newFormValues);
    }
    else if(event.target.id.includes('withholding')){
      setPaycheckValues(utilWithholding(event, newFormValues));
    }
    else{
      newFormValues = utilUpdateWithholding(newFormValues, event.target.name, "payFrequency-", 'payFrequency', event.target.value)
      setPaycheckValues(newFormValues);
    }
  }

  const estimatedFedPaid = useMemo(() => {
    return utilEstimatedTaxPaid(paycheckValues, q1Taxes, q2Taxes, q3Taxes, q4Taxes)
  }, [q1Taxes, q2Taxes, q3Taxes, q4Taxes, paycheckValues]);

  const SHBTaxByPrev = useMemo(() => {
    if(prevFedTaxes > 0){
      return Math.ceil((prevFedTaxes * 1.1))
    }
    return 0
  }, [prevFedTaxes]);

  const SHBTaxByPrevDeltaPerMonth = useMemo(() => {
    if(SHBTaxByPrev-estimatedFedPaid > 0){
      return Math.ceil((SHBTaxByPrev-estimatedFedPaid)/remainingMonths)
    }
    else {
      return 0
    }
  }, [SHBTaxByPrev, estimatedFedPaid]);

  const SHBTaxByPrevDeltaPerQuarter = useMemo(() => {
    if(SHBTaxByPrev-estimatedFedPaid > 0){
      return Math.ceil((SHBTaxByPrev-estimatedFedPaid)/remainingQuarters())
    }
    else {
      return 0
    }
  }, [SHBTaxByPrev, estimatedFedPaid]);

  const TaxByCurr = useMemo(() => {
    let income = projectedIncome - deductions
    let tax = 0
    let prev = 0
    if(projectedIncome > 0){
      for (const [key, value] of Object.entries(federalRates[filingStatus])) {
        if(income > key){
          tax = tax + ((key - prev) * value)
        } else {
          tax = tax + ((income - prev) * value)
          prev = key
          break
        }
        prev = key
      }
      if(income - prev>0){
        tax = tax + ((income-prev) * 0.37)
      }
      return Math.ceil(tax)
    }
    return 0
  }, [filingStatus, projectedIncome, deductions]);

  const SHBTaxByCurr = useMemo(() => {
    return Math.ceil((TaxByCurr * 0.9))
  }, [TaxByCurr]);

  const SHBTaxByCurrDeltaPerMonth = useMemo(() => {
    if(SHBTaxByCurr-estimatedFedPaid > 0){
      return  Math.ceil((SHBTaxByCurr-estimatedFedPaid)/remainingMonths)
    }
    else {
      return 0
    }
  }, [SHBTaxByCurr, estimatedFedPaid]);

  const SHBTaxByCurrDeltaPerQuarter = useMemo(() => {
    if(SHBTaxByCurr-estimatedFedPaid > 0){
      return Math.ceil((SHBTaxByCurr-estimatedFedPaid)/remainingQuarters())
    }
    else {
      return 0
    }
  }, [SHBTaxByCurr, estimatedFedPaid]);

  const handleChange = (event) => {
    // console.log(event.target.id)
    if(event.target.id === "prev-fed-taxes"){
      setPrevFedTaxes(parseInt(event.target.value))
      if(event.target.value == ""){setPrevFedTaxes(0)}
    }
    else if(event.target.id === "projected-income"){
      setProjectedIncome(parseInt(event.target.value))
      if(event.target.value == ""){setProjectedIncome(0)}
      if(styling === "page") {props.onSubmitData({"projectedIncome": parseInt(event.target.value),"filingStatus": filingStatus})}
    }
    else if(event.target.id === "q1-taxes"){
      setQ1Taxes(parseInt(event.target.value))
      if(event.target.value == ""){setQ1Taxes(0)}
    }
    else if(event.target.id === "q2-taxes"){
      setQ2Taxes(parseInt(event.target.value))
      if(event.target.value == ""){setQ2Taxes(0)}
    }
    else if(event.target.id === "q3-taxes"){
      setQ3Taxes(parseInt(event.target.value))
      if(event.target.value == ""){setQ3Taxes(0)}
    }
    else if(event.target.id === "q4-taxes"){
      setQ4Taxes(parseInt(event.target.value))
      if(event.target.value == ""){setQ4Taxes(0)}
    }
    else if(event.target.id === "deductions"){
      setDeductions(parseInt(event.target.value))
      if(event.target.value == ""){setDeductions(0)}
    }
    else if(typeof event.target.id === "undefined"){
      setfilingStatus(event.target.value)
      if(styling === "page") {props.onSubmitData({"projectedIncome": projectedIncome,"filingStatus": event.target.value})}
    }
  };

  const [message, setMessage] = useState("")
  const [msgStatus, setMsgStatus] = useState("")
  const onSave = async(event) => {
    let onTrack = false
    if(((SHBTaxByPrev-estimatedFedPaid) <= (estimatedFedPaid*0.1)) || ((SHBTaxByCurr-estimatedFedPaid) <= (estimatedFedPaid*0.1))){
      onTrack = ""
    }
    if(SHBTaxByPrev-estimatedFedPaid <= 0 || SHBTaxByCurr-estimatedFedPaid <= 0){
      onTrack = true
    }
    const payload = {
      "id": props.id,
      "federal": {
        "prevFedTaxes": prevFedTaxes,
        "filingStatus": filingStatus,
        "projectedIncome": projectedIncome,
        "onTrack": onTrack,
        "quarterly": {
          "q1Taxes": q1Taxes,
          "q2Taxes": q2Taxes,
          "q3Taxes": q3Taxes,
          "q4Taxes": q4Taxes
        },
        "paycheckValues": paycheckValues,
        "deductions": deductions
      },
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}clients/updatefed`,
        payload, {
        headers:{
          Authorization: `Bearer ${props.accessToken}`
        }
      })
      setMessage("Record Updated")
      setMsgStatus("success")
      props.onSubmitData(payload)
    } catch (err) {
      setMessage("Error while saving.")
      setMsgStatus("error")
      console.error(err)
    }
  }

  const onReset = async(event) => {
    setPrevFedTaxes(0)
    setProjectedIncome(0)
    setfilingStatus("single")
    setQ1Taxes(0)
    setQ2Taxes(0)
    setQ3Taxes(0)
    setQ4Taxes(0)
    setPaycheckValues([{YTDTaxes:0, withholding:0, payFrequency:"bi-weekly"}])
    setDeductions(0)
  }

  const style = () => {
    let styles = {
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '65%',
      height: '90%',
      bgcolor: 'background.paper',
      border: '2px solid #ED3C6A',
      boxShadow: 24,
      overflow:'scroll',
      pt: 2,
      px: 4,
      pb: 3,
      }
    if(styling === "page") {
      styles = {
        border: '1px solid #f48fb1',
        pt: 2,
        px: 4,
        pb: 3,
      }
    }
    return styles
  };

  return (
    <Box sx={{ ...style(), flexGrow: 1,  mt:5 , mb:5 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ color: 'primary.main', textTransform: 'uppercase'}}>
            Withholdings
          </Box>
        </Grid>
        {paycheckValues.map((paycheck, index) => (
          <Grid item xs={12} md={12} key={index}>
            {index > 0 &&
              <Divider sx={{ bgcolor: "primary.main", mb:3, }}/>
            }
            <Stack spacing={1} direction={{ xs: 'column', md: 'row' }} sx={{m:1, p:0}} alignItems="center">
              <AmountInput 
                id={"ytd-taxes-"+index}
                name="YTDTaxes"
                label={"Taxes withheld YTD as of " + last_day_prev_month()}
                placeholder="5000"
                handleChange={paycheckChange}
                value={paycheck.YTDTaxes}
              />
              <AmountInput 
                id={"withholding-"+index} 
                name="withholding"
                label={"Planned withholding / paycheck"}
                placeholder="500"
                handleChange={paycheckChange}
                value={paycheck.withholding}
              />
              <RadioInput
                id={"pay-frequency-"+index}
                name={"payFrequency-"+index}
                label="Paycheck Frequency"
                handleChange={paycheckChange}
                value={paycheck.payFrequency}
                options={freqOptions}
              />
              <IconButton size="small" onClick={() => removePayCheck(index)}  sx={{ display:{xs:'flex', md:'auto'}, width:{xs:'100%', md:'auto'}, justifyContent: { xs: 'flex-end', md: 'flex-start' } }}>
                <DeleteIcon fontSize="small" /> <Typography variant="subtitle1" sx={{display: { xs: 'auto', sm: 'none' }}}> Paycheck</Typography>
              </IconButton>
            </Stack>
          </Grid>
        ))}
        <Grid container justifyContent={{ xs: 'left', md: 'right'}} sx={{mt:0}}>
          <Button variant="outlined" color="secondary" onClick={addPaycheck}>Add Paycheck</Button>
        </Grid>
        <Grid container spacing={2} sx={{p:2 }}>
          <Grid item xs={12}>
            <Box sx={{ color: 'primary.main', textTransform: 'uppercase'}}>
              Quaterly Taxes
            </Box>
          </Grid>
          <DatesListForm q1Taxes={q1Taxes} q2Taxes={q2Taxes} q3Taxes={q3Taxes} q4Taxes={q4Taxes} handleChange={handleChange}/>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ color: 'primary.main', textTransform: 'uppercase' }}>
            110% Safe Harbor
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <AmountInput 
            id="prev-fed-taxes" 
            label={labelPrevYear() + " Federal Tax" }
            placeholder="20000"
            handleChange={handleChange}
            value={prevFedTaxes}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ color: 'primary.main', textTransform: 'uppercase' }}>
            90% Safe Harbor
          </Box>
          <Typography variant="caption" gutterBottom> {"Lower payments if "+labelCurrYear()+" projected income has not significantly increased from previous year"}.</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <AmountInput 
            id="projected-income" 
            label={"Projected Income "+ labelCurrYear()}
            placeholder="100000"
            handleChange={handleChange}
            value={projectedIncome}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <AmountInput 
            id="deductions" 
            label="Deductions & Credits"
            placeholder="10000"
            handleChange={handleChange}
            value={deductions}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectInput 
            id="filing-status" 
            label={labelCurrYear() + " Filing Status" }
            labelId="filing-status-label"
            handleChange={handleChange}
            options={filingStatuses}
            value={filingStatus}
          />
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: "#ED3C6A", m:1 }}/>
      <Grid  container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ color: 'primary.main', textTransform: 'uppercase', p:1 }}>
            <Typography variant="subtitle1" gutterBottom> Progress towards 110% Safe Harbor</Typography>
          </Box>
          <EstimateTaxGauge 
            value={estimatedFedPaid}
            valueMax={SHBTaxByPrev}
          />
          <PaymentList
            monthly={SHBTaxByPrevDeltaPerMonth}
            quarterly={SHBTaxByPrevDeltaPerQuarter}
          />
        </Grid>
        {projectedIncome>0 &&
        <>
          <Divider orientation="vertical" flexItem variant="middle" sx={{ mr: "-1px", mt:5, mb:5,  bgcolor: "#ED3C6A", display: { xs: 'none', sm: 'block' } }} />
          <Grid item xs={12} md={6}>
            <Box sx={{ color: 'primary.main', textTransform: 'uppercase', p:1 }}>
              <Typography variant="subtitle1" gutterBottom> Progress towards 90% Safe Harbor</Typography>
            </Box>
            <EstimateTaxGauge 
              value={estimatedFedPaid}
              valueMax={SHBTaxByCurr}
            />
            <PaymentList
              monthly={SHBTaxByCurrDeltaPerMonth}
              quarterly={SHBTaxByCurrDeltaPerQuarter}
              tax={TaxByCurr}
            />
          </Grid>
        </>}
      </Grid>
      {styling === "modal" &&
        <>
        <Grid container justifyContent={{ xs: 'center', md: 'right' }} sx={{mt:2}}>
          <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}  justify="flex-end" sx={{m:1}}>
            <Alert severity={msgStatus}>
              {message}
            </Alert>
          </Stack>
          <Stack spacing={2} direction='row'  justify="flex-end" sx={{m:1}}>
            <Button variant="outlined" color="secondary" onClick={onReset}>Reset</Button>
            <Button variant="contained" color="secondary" onClick={onSave}>Save</Button>
          </Stack>
        </Grid>
        <Typography variant="body2" gutterBottom>
          Last updated on {props.federal.updateDate}
        </Typography>
        </>
      }
    </Box>
  );
});
export default FederalForm;