import React, { useState, useMemo, useRef, useEffect } from "react";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import AmountInput from "../components/AmountInput";
import RadioInput from "../components/RadioInput";
import SelectInput from "./SelectInput";
import EstimateTaxGauge from "../components/EstimateTaxGauge";
import Grid from '@mui/material/Grid';
import DatesListForm from "./DateListForm";
import { stateOptions, statesRates, statesDates, federalDates } from "../data/taxrates";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import PaymentList from "./PaymentList";
import CelebrationIcon from '@mui/icons-material/Celebration';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {withAuthInfo} from "@propelauth/react"
import {remainingMonths, last_day_prev_month, labelPrevYear, remainingQuarters, labelCurrYear
} from "../helpers/DateUtils"
import {utilEstimatedTaxPaid, freqOptions, utilAddPaycheck , utilRemovePayCheck,
  utilWithholding, utilUpdateWithholding } from "../helpers/EstimateUtils"
import axios from 'axios'
axios.defaults.withCredentials = true

const StateForm = withAuthInfo((props) => {
  const [stateName, setStateName] = useState(typeof props.state != "undefined" && typeof props.state.name != "undefined" ? props.state.name : "" );
  const [prevStateTaxes, setPrevStateTaxes] = useState(typeof props.state != "undefined" && typeof props.state.prevStateTaxes != "undefined" ? props.state.prevStateTaxes : 0);
  const federalProjectedIncome = typeof props.projectedIncome != "undefined" ? props.projectedIncome : 0
  const [projectedIncome, setProjectedIncome] = useState(typeof props.state != "undefined" && typeof props.state.projectedIncome != "undefined" ? props.state.projectedIncome : federalProjectedIncome);
  const [q1Taxes, setQ1Taxes] = useState(typeof props.state != "undefined" && typeof props.state.quarterly != "undefined" && typeof props.state.quarterly.q1Taxes != "undefined" ? props.state.quarterly.q1Taxes : 0);
  const [q2Taxes, setQ2Taxes] = useState(typeof props.state != "undefined" && typeof props.state.quarterly != "undefined" && typeof props.state.quarterly.q2Taxes != "undefined" ? props.state.quarterly.q2Taxes : 0);
  const [q3Taxes, setQ3Taxes] = useState(typeof props.state != "undefined" && typeof props.state.quarterly != "undefined" && typeof props.state.quarterly.q3Taxes != "undefined" ? props.state.quarterly.q3Taxes : 0);
  const [q4Taxes, setQ4Taxes] = useState(typeof props.state != "undefined" && typeof props.state.quarterly != "undefined" && typeof props.state.quarterly.q4Taxes != "undefined" ? props.state.quarterly.q4Taxes : 0);
  const [paycheckValues, setPaycheckValues] = useState(typeof props.state != "undefined" && typeof props.state.paycheckValues != "undefined" ? props.state.paycheckValues :[{YTDTaxes:0, withholding:0, payFrequency:"bi-weekly"}])
  const [deductions, setDeductions] = useState(typeof props.state != "undefined" && typeof props.state.deductions != "undefined" ? props.state.deductions : 0);
  const styling = props.styling?props.styling:"modal"

  const addPaycheck = async(event) => {
    setPaycheckValues(utilAddPaycheck(paycheckValues))
  }
  const removePayCheck = (i) => {
    setPaycheckValues(utilRemovePayCheck(i, paycheckValues))
  }
  const paycheckChange = (event) => {
    let newFormValues = [...paycheckValues];
    if(event.target.id.includes('ytd-taxes')){
      newFormValues = utilUpdateWithholding(newFormValues, event.target.id, "ytd-taxes-", event.target.name, event.target.value)
      setPaycheckValues(newFormValues);
    }
    else if(event.target.id.includes('withholding')){
      setPaycheckValues(utilWithholding(event, newFormValues));
    }
    else{
      newFormValues = utilUpdateWithholding(newFormValues, event.target.name, "payFrequency-", 'payFrequency', event.target.value)
      setPaycheckValues(newFormValues);
    }
  }

  const estimatedStatePaid = useMemo(() => {
    return utilEstimatedTaxPaid(paycheckValues, q1Taxes, q2Taxes, q3Taxes, q4Taxes)
  }, [q1Taxes, q2Taxes, q3Taxes, q4Taxes, paycheckValues]);

  const SHBStateTaxByPrev = useMemo(() => {
    if(prevStateTaxes > 0){
      return Math.ceil((prevStateTaxes * 1.1))
    }
    return 0
  }, [prevStateTaxes]);

  const SHBStateTaxByPrevDeltaPerMonth = useMemo(() => {
    if(SHBStateTaxByPrev-estimatedStatePaid > 0){
      return Math.ceil((SHBStateTaxByPrev-estimatedStatePaid)/remainingMonths)
    }
    else {
      return 0
    }
  }, [SHBStateTaxByPrev, estimatedStatePaid]);

  const SHBStateTaxByPrevDeltaPerQuarter = useMemo(() => {
    if(SHBStateTaxByPrev-estimatedStatePaid > 0){
      return Math.ceil((SHBStateTaxByPrev-estimatedStatePaid)/remainingQuarters(stateName))
    }
    else {
      return 0
    }
  }, [SHBStateTaxByPrev, estimatedStatePaid]);

  const StateTaxByCurr = useMemo(() => {
    let income = projectedIncome - deductions
    let tax = 0
    let prev = 0
    let filingStatus = "single"
    if(props.filingStatus === "married_j"){
      filingStatus = props.filingStatus
    }
    if(projectedIncome > 0){
      if(statesRates.hasOwnProperty(stateName)){
        if(statesRates[stateName][filingStatus].hasOwnProperty('rates')){
          for (const [key, value] of Object.entries(statesRates[stateName][filingStatus]['rates'])) {
            if(income > key){
              tax = tax + ((key - prev) * value)
            } else {
              tax = tax + ((income - prev) * value)
              prev = key
              break
            }
            prev = key
          }
        }
        if(income - prev >0){
          tax = tax + ((income - prev) * statesRates[stateName][filingStatus]['upper'])
        }
        return Math.ceil(tax)
      }
      else {
        return 0
      }
    }
    return 0
  }, [projectedIncome, stateName, deductions]);

  const SHBStateTaxByCurr = useMemo(() => {
    return Math.ceil((StateTaxByCurr * 0.9))
  }, [StateTaxByCurr]);

  const SHBStateTaxByCurrDeltaPerMonth = useMemo(() => {
    if(SHBStateTaxByCurr-estimatedStatePaid > 0){
      return Math.ceil((SHBStateTaxByCurr-estimatedStatePaid)/remainingMonths)
    }
    else {
      return 0
    }
  }, [SHBStateTaxByCurr, estimatedStatePaid]);

  const SHBStateTaxByCurrDeltaPerQuarter = useMemo(() => {
    if(SHBStateTaxByCurr-estimatedStatePaid > 0){
      return Math.ceil((SHBStateTaxByCurr-estimatedStatePaid)/remainingQuarters(stateName))
    }
    else {
      return 0
    }
  }, [SHBStateTaxByCurr, estimatedStatePaid]);

  // hide no state tax on new form
  let newForm = true
  if(stateName !== ""){
    newForm=false
  }
  const handleChange = (event) => {
    if(event.target.id === "prev-state-taxes"){
      setPrevStateTaxes(parseInt(event.target.value))
      if(event.target.value == ""){setPrevStateTaxes(0)}
    }
    else if(event.target.id === "projected-income"){
      setProjectedIncome(parseInt(event.target.value))
      if(event.target.value == ""){setProjectedIncome(0)}
    }
    else if(event.target.id === "q1-taxes"){
      setQ1Taxes(parseInt(event.target.value))
      if(event.target.value == ""){setQ1Taxes(0)}
    }
    else if(event.target.id === "q2-taxes"){
      setQ2Taxes(parseInt(event.target.value))
      if(event.target.value == ""){setQ2Taxes(0)}
    }
    else if(event.target.id === "q3-taxes"){
      setQ3Taxes(parseInt(event.target.value))
      if(event.target.value == ""){setQ3Taxes(0)}
    }
    else if(event.target.id === "q4-taxes"){
      setQ4Taxes(parseInt(event.target.value))
      if(event.target.value == ""){setQ4Taxes(0)}
    }
    else if(event.target.id === "deductions"){
      setDeductions(parseInt(event.target.value))
      if(event.target.value == ""){setDeductions(0)}
    }
    else {
      //target.id is undefined for select
      setStateName(event.target.value)
      newForm=false
    }
  };

  const [message, setMessage] = useState("")
  const [msgStatus, setMsgStatus] = useState("")
  const [stateError, setStateError] = useState(false)
  const onSave = async(event) => {
    //Validation
    if(stateName===""){
      setStateError("error")
      setMessage("Enter the required field")
      setMsgStatus("error")
      return
    }
    else{
      setStateError(false) //clearing error msg
    }

    let onTrack = false
    if(((SHBStateTaxByCurr-estimatedStatePaid) <= (estimatedStatePaid*0.1)) || ((SHBStateTaxByPrev-estimatedStatePaid) <= (estimatedStatePaid*0.1))){
      onTrack = ""
    }
    if(SHBStateTaxByCurr-estimatedStatePaid <= 0 || SHBStateTaxByPrev-estimatedStatePaid <= 0){
      onTrack = true
    }
    const payload = {
      "id": props.id,
      "state": {
        "name":stateName,
        "prevStateTaxes": prevStateTaxes,
        "projectedIncome": projectedIncome,
        "onTrack": onTrack,
        "quarterly": {
          "q1Taxes": q1Taxes,
          "q2Taxes": q2Taxes,
          "q3Taxes": q3Taxes,
          "q4Taxes": q4Taxes
        },
        "paycheckValues": paycheckValues,
        "deductions": deductions
      },
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}clients/updatestate`,
        payload,{
        headers:{
          Authorization: `Bearer ${props.accessToken}`
        }
      })
      setMessage("Record Updated")
      setMsgStatus("success")
      props.onSubmitData(payload)
    } catch (err) {
      setMessage("Error while saving.")
      setMsgStatus("error")
      console.error(err)
    }
  }

  const onReset = async(event) => {
    setPrevStateTaxes(0)
    setProjectedIncome(0)
    setQ1Taxes(0)
    setQ2Taxes(0)
    setQ3Taxes(0)
    setQ4Taxes(0)
    setPaycheckValues([{YTDTaxes:0, withholding:0, payFrequency:"bi-weekly"}])
    setDeductions(0)
  }

  const onDelete = async(event) => {
    const payload = {
      "id": props.id,
      "state": {
        "name":stateName
      },
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}clients/deletestate`,
        payload,{
        headers:{
          Authorization: `Bearer ${props.accessToken}`
        }
      })
      props.onStateDelete(stateName)
    } catch (err) {
      setMessage("Error while deleting.")
      setMsgStatus("error")
      console.error(err)
    }
  }

  const style = () => {
    let styles = {
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '65%',
      height: '90%',
      bgcolor: 'background.paper',
      border: '2px solid #ED3C6A',
      boxShadow: 24,
      overflow:'scroll',
      pt: 2,
      px: 4,
      pb: 3,
      }
    if(styling === "page") {
      styles = {
        border: '1px solid #f48fb1',
        pt: 2,
        px: 4,
        pb: 3,
      }
    }
    return styles
  };

  return (
    <Box
      sx={{ ...style(), flexGrow: 1,  mt:5 , mb:5 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectInput 
            id="state-name" 
            label={"State" }
            labelId="state-name-label"
            handleChange={handleChange}
            options={stateOptions}
            value={stateName}
            required={props.required}
            disabled={props.disabled}
            error={stateError}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ color: 'primary.main', textTransform: 'uppercase' }}>
            Withholdings
          </Box>
        </Grid>
        {paycheckValues.map((paycheck, index) => (
          <Grid item xs={12} md={12} key={index}>
            {index > 0 &&
              <Divider sx={{ bgcolor: "primary.main", mb:3, }}/>
            }
            <Stack spacing={1} direction={{ xs: 'column', md: 'row' }} sx={{m:1, p:0}} alignItems="center">
              <AmountInput 
                id={"ytd-taxes-"+index}
                name="YTDTaxes"
                label={"Taxes withheld YTD as of " + last_day_prev_month()}
                placeholder="5000"
                handleChange={paycheckChange}
                value={paycheck.YTDTaxes}
              />
              <AmountInput 
                id={"withholding-"+index} 
                name="withholding"
                label={"Planned withholding per paycheck"}
                placeholder="500"
                handleChange={paycheckChange}
                value={paycheck.withholding}
              />
              <RadioInput
                id={"pay-frequency-"+index}
                name={"payFrequency-"+index}
                label="Paycheck Frequency"
                handleChange={paycheckChange}
                value={paycheck.payFrequency}
                options={freqOptions}
              />
              <IconButton size="small"  onClick={()=>removePayCheck(index)} sx={{ display:{xs:'flex', md:'auto'}, width:{xs:'100%', md:'auto'}, justifyContent: { xs: 'flex-end', md: 'flex-start' } }}>
                <DeleteIcon fontSize="small" /> <Typography variant="subtitle1" sx={{display: { xs: 'auto', sm: 'none' }}}> Paycheck</Typography>
              </IconButton>
            </Stack>
          </Grid>
        ))}
        <Grid container justifyContent={{ xs: 'left', md: 'right' }} sx={{mt:0}}>
          <Button variant="outlined" color="secondary" onClick={addPaycheck}>Add Paycheck</Button>
        </Grid>
        <Grid container spacing={2} sx={{p:2 }}>
          <Grid item xs={12}>
            <Box sx={{ color: 'primary.main', textTransform: 'uppercase' }}>
              Quaterly Taxes 
            </Box>
          </Grid>
          <DatesListForm q1Taxes={q1Taxes} q2Taxes={q2Taxes} q3Taxes={q3Taxes} q4Taxes={q4Taxes} handleChange={handleChange} stateName={stateName}/>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ color: 'primary.main', textTransform: 'uppercase' }}>
            110% SAFE HARBOR
          </Box>
        </Grid>
        <Grid item xs={12}>
          <AmountInput 
            id="prev-state-taxes" 
            label={labelPrevYear() + " State Tax" }
            placeholder="5000"
            handleChange={handleChange}
            value={prevStateTaxes}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ color: 'primary.main', textTransform: 'uppercase' }}>
            90% SAFE HARBOR
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <AmountInput 
            id="projected-income" 
            label={"Projected Income "+ labelCurrYear()}
            placeholder="80000"
            handleChange={handleChange}
            value={projectedIncome}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AmountInput 
            id="deductions" 
            label="Deductions & Credits"
            placeholder="800"
            handleChange={handleChange}
            value={deductions}
          />
        </Grid>
      </Grid>
      <Divider sx={{ bgcolor: "#ED3C6A", m:1 }}/>
      {statesRates.hasOwnProperty(stateName)
        ?<>
          <Grid  container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ color: 'primary.main', textTransform: 'uppercase', p:1 }}>
                <Typography variant="subtitle1" gutterBottom> Progress towards 110% Safe Harbor</Typography>
              </Box>
              <EstimateTaxGauge 
                value={estimatedStatePaid}
                valueMax={SHBStateTaxByPrev}
              />
              <PaymentList
                monthly={SHBStateTaxByPrevDeltaPerMonth}
                quarterly={SHBStateTaxByPrevDeltaPerQuarter}
              />
            </Grid>
            {projectedIncome>0 &&
            <>
              <Divider orientation="vertical" flexItem variant="middle" sx={{ mr: "-1px", mt:5, mb:5,  bgcolor: "#ED3C6A", display: { xs: 'none', sm: 'block' } }} />
              <Grid item xs={12} md={6}> 
                <Box sx={{ color: 'primary.main', textTransform: 'uppercase', p:1 }}>
                  <Typography variant="subtitle1" gutterBottom> Progress towards 90% Safe Harbor</Typography>
                </Box>
                <EstimateTaxGauge 
                  value={estimatedStatePaid}
                  valueMax={SHBStateTaxByCurr}
                />
                <PaymentList
                  monthly={SHBStateTaxByCurrDeltaPerMonth}
                  quarterly={SHBStateTaxByCurrDeltaPerQuarter}
                  tax={StateTaxByCurr}
                />
              </Grid>
            </>}
          </Grid>
        </>
        :<Box sx={{ color: 'primary.main', textTransform: 'uppercase', p:1 }}>
          <Typography variant="subtitle1" gutterBottom> 
            {!newForm &&
              <><CelebrationIcon/> No State Tax</>
            }          
          </Typography>
        </Box>
      }
      {styling === "modal" &&
        <>
        <Grid container justifyContent={{ xs: 'center', md: 'right' }} sx={{mt:2}}>
          <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}  justify="flex-end" sx={{m:1}}>
            <Alert severity={msgStatus}>
              {message}
            </Alert>
          </Stack>
          <Stack spacing={2} direction='row'  justify="flex-end" sx={{m:1}}>
            <Button variant="outlined" color="error" onClick={onDelete}>Delete</Button>
            <Button variant="outlined" color="secondary" onClick={onReset}>Reset</Button>
            <Button variant="contained" color="secondary" onClick={onSave}>Save</Button>
          </Stack>
        </Grid>
        {props.state && 
          <Typography variant="body2" gutterBottom>
            Last updated on {props.state.updateDate}
          </Typography>
        } 
        </>
      }
    </Box>
  );
});
export default StateForm;