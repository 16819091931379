import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

function EstimateTaxGauge(props) {
  return (
    <Gauge 
      width={250} 
      height={200} 
      value={props.value} 
      valueMin={0} 
      valueMax={props.valueMax} 
      startAngle={-110} 
      endAngle={110}
      sx={{
        [`& .${gaugeClasses.valueText}`]: {
          fontSize: 18,
        },
      }}
      text={"$" +props.value.toLocaleString()+" / $"+props.valueMax.toLocaleString()}
    />
  );
}
export default EstimateTaxGauge;