import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

function AmountInput(props) {
  return (
    <TextField sx={{ m: 1}}
      required={props.required}
      id={props.id}
      label={props.label}
      type="Number"
      placeholder={props.placeholder}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      onChange={props.handleChange} 
      value={props.value}
      size={props.size}
      defaultValue={props.defaultValue}
      name={props.name}
    /> 
  );
}

export default AmountInput;